
import { useEffect, useState } from 'react'

import OtraDalaTelefons from './OtraDalaTelefons'
import OtraDalaDators from './OtraDalaDators'


export default function OtraDala() {


    // Declare a new state variable with the "useState" Hook
    const [width, setWidth] = useState(window.innerWidth);


    useEffect(() => {

        window.addEventListener("resize", () => setWidth(window.innerWidth));

    }, [window.innerWidth]);

    return (
        <div  id='proceduras'>
            {width < 768 ?

                <div>

                    <h2 style={{margin: '3rem 1rem 1rem 1rem'}}><b>Liekā svara un celulīta ārstēšanai tiek piedāvātas šādas procedūras</b></h2>

                    <OtraDalaTelefons />
                </div>

                :

                <div style={{ backgroundColor: 'rgba(0, 0, 255, 0.3)', marginTop: '3rem', marginBottom: '1rem', paddingTop: '2rem' }}>

                    <h2 style={{marginTop: '3rem', marginBottom: '1rem'}}><b>Liekā svara un celulīta ārstēšanai tiek piedāvātas šādas procedūras</b></h2>

                    <OtraDalaDators />
                </div>
            }

        </div>
    )
}