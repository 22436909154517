import style from './Kontakti.module.css'

import { Button } from 'react-bootstrap'

export default function Kontakti() {



    return (
        <div id='kontakti' className={style.kontakti}>
            <h1><b>Kontakti</b></h1>
            <h4>Telefons: <b>26465017</b></h4>

            <a className={style.button} href="https://www.facebook.com/WELLSIMCENTRSCESIS" target="_blank">  <button type="button" className={`${style.btn} ${style.facebook}`}> <i class="fab fa-facebook-square"></i><b>Facebook</b></button> </a>
            <a className={style.button} href="https://www.instagram.com/aiva_parandjuka/" target="_blank">  <button type="button" className={`${style.btn} ${style.instagram}`}> <i class="fab fa-instagram"></i><b>Instagram</b></button> </a>

            <iframe className={style.map}
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2154.804953557077!2d25.27521421573302!3d57.31119018099869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ebe18fec6a391f%3A0x9e10cacdb44079f4!2zS3JpxaFqxIHFhmEgVmFsZGVtxIFyYSBpZWxhIDExLCBDxJNzaXMsIEPEk3N1IHBpbHPEk3RhLCBMVi00MTAx!5e0!3m2!1sen!2slv!4v1635188886852!5m2!1sen!2slv"
    width='100%' height="450" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>

        </div>
    )
}