import styles from './Signup.module.css'

import { useState, useEffect } from 'react'

import { useAuthContext, user } from '../../../Hooks/useAuthContext';
import { useSignup } from '../../../Hooks/useSignup'
import { useFirestore } from '../../../Hooks/useFirestore'


// styles


export default function Signup() {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [location, setLocation] = useState('')
  const [ Zipcode, setZipcode] = useState('')

  const { signup, isPending, error } = useSignup()
  
  const { addDocument, response } = useFirestore('people')
  const { authIsReady, user } = useAuthContext()
  

  const handleSubmit = (e) => {
    e.preventDefault()
    
    signup(name, surname, email, password, `${name} ${surname}`)

  }


  return (
    <form onSubmit={handleSubmit} className={styles['signup-form']}>
      <h2>Reģistrēties!</h2>
      <label>
        <span>Vārds</span>
        <input 
          type="name" 
          onChange={(e) => setName(e.target.value)} 
          value={name}
          required
        />
      </label>
      <label>
        <span>Uzvārds</span>
        <input 
          type="surname" 
          onChange={(e) => setSurname(e.target.value)} 
          value={surname}
          required
        />
      </label>
      <label>
        <span>E-pasts</span>
        <input 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
          required
        />
      </label>
      <label>
        <span>Parole (Jūsu pašu izdomāta. Vismaz 6 simboli. Atcerieties paroli!)</span>
        <input 
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password}
          required
        />
      </label>

   
      { !isPending && <button className={styles.button}><b>Reģistrēties</b></button> }
      { isPending && <p disabled>loading</p> }
      { error && <p>{error}</p> }
      
    </form>
  )
}