
import style from './VideoPaka.module.css'
import Login from '../../LogIn/LogIn'
import Signup from './SignUp/SignUp'

import { Container, Row, Col, Button } from 'react-bootstrap'
import { useState } from 'react'
import { NavLink } from "react-router-dom"

import React from "react";

import jsPDF from 'jspdf'


import '../../fonts/PTSans-Regular.ttf'
import '../../fonts/Arimo-Regular.ttf'


export default function VideoPaka() {


  const [login, setLogin] = useState(false)




  return (
    <div className={style.videoPaka} id='online-vingrosana'>



      <h1><b>Online vingrošanas nodarbības</b></h1>


      <Container>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/2aHQlgz26-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        {/*
<iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F100011423605496%2Fvideos%2F434905834710122%2F&show_text=false&width=476&t=0" width="476" height="476" scrolling="no" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
  */}
        <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F100011423605496%2Fvideos%2F576073993496948%2F&show_text=false&width=476&t=0" width="476" height="476" scrolling="no" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>


        <Row>
          <Col sm={6} lg={3} >
            <div className={style.bundleBox}>
              <h5>3 Nodarbības</h5>

              <h6>Nodarbību garums</h6>
              <ul>
                <li>2x 40min</li>
                <li>1x 60min</li>
              </ul>

            </div>

            <div className={style.bundleBoxPrice}>
              <h6>Cena: 5 €</h6>
              </div>
          </Col>

          <Col sm={6} lg={3}>
            <div className={style.bundleBox}>
              <h5>8 Nodarbības</h5>

              <h6>Nodarbību garums</h6>
              <ul>
                <li>4x 40min</li>
                <li>4x 60min</li>
              </ul>
            </div>

            <div className={style.bundleBoxPrice}>
              <h6>Cena: 13 €</h6>
              </div>
          </Col>

          <Col sm={6} lg={3}>
            <div className={style.bundleBox}>
              <h5>12 Nodarbības</h5>

              <h6>Nodarbību garums</h6>
              <ul>
                <li>8x 40min</li>
                <li>4x 60min</li>
              </ul>
            </div>

            <div className={style.bundleBoxPrice}>
              <h6>Cena: 19 €</h6>
              </div>
          </Col>

          <Col sm={6} lg={3}>
            <div className={style.bundleBox}>
              <h5>16 Nodarbības</h5>

              <h6>Nodarbību garums</h6>
              <ul>
                <li>10x 40min</li>
                <li>6x 60min</li>
              </ul>
            </div>

            <div className={style.bundleBoxPrice}>
              <h6>Cena: 25 €</h6>
              </div>
          </Col>

          <Col sm={12} lg={12} className={style.colKreis}>

            <h3><b>VINGROJAM KOPĀ!</b></h3>
            <ul style={{ 'textAlign': 'left' }}>
              <li>Video treniņu programmas sev ērtā vietā un laikā</li>
              <li>Daudzveidīgi vingrojumi no vieglas līdz vidējai intensitātei</li>
              <li>Nodarbības mērķis - muskuļu korsetes stabilizācija, dziļo muskuļu un vispārējā fiziskā tonusa attīstīšana</li>
              <li>Katra nodarbība ir pieejama 24h no nodarbības atvēršanas brīža</li>
              <li>Treniņu programmas kompleksam nav laika ierobežojums</li>
              <li>Treniņu programma slēdzas brīdī, kad visas nodarbības ir bijušas atvērtas un ir pagājušas 24h kopš pēdējās nodarbības atvēršanas</li>
            </ul>



            <h5><b>Nepieciešamais inventārs</b></h5>
            <ul style={{ 'textAlign': 'left' }}>
              <li>vingrošanas paklājs</li>
              <li>nūja</li>
              <li>bumba</li>
              <li>divi nelieli dvielīši</li>
              <li>mazās vingrošanas hanteles vai 0.5L ūdens pudelītes</li>
              <li>mazā vingrošanas gumija</li>
            </ul>

            <h5><b>Tikai mērķtiecīga un regulāra darbība, noved pie vēlamā rezultāta!</b></h5>
            <NavLink className={style.button} to="/login">Reģistrēties</NavLink>

          </Col>


        </Row>


      </Container>

    </div>
  )
}

