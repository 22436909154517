import BannerImg from '../../images/Oils.jpg'
import './Banner.css'

export default function Banner() {

    return (
        <div id='sakums'>
            <div className='banner'>
                <img className="banner-image" src={BannerImg} alt="Salons siluets - masāžas un procedūras" />


                <div className="banner-text-head">
                    Salons Siluets
                </div>
                <div className="banner-text-foot">
                    Cēsīs!
                </div>
            </div>

           

            <div className="intro">
            <hr className='solid' />
                
                <p>
                    Salons "Siluets" atrodas Cēsīs. Salonā "Siluets” pieejamas četras dažādas "Wellslim System" aparatūras, ar kurām var
                    individuāli risināt liekā svara un celulīta radītās problēmas, kā arī veikt liftingu sejai,
                    samazināt krunciņas un dubultzodu.
                </p>
 <hr className='solid' />
            </div>

           
           
        </div>
    )
}