
import './Telefons.css'

import { Accordion, Badge } from 'react-bootstrap'


export default function PirmaDalaTelefons() {

    return (
        <div style={{ marginTop: '1rem' }}>

            <div className='telefons pirmaDalaTelefons'>
                <Accordion defaultActiveKey="0" >

                    <Accordion.Item eventKey="0">
                        <Accordion.Header> <h3>Individuāla vingrošana  <Badge bg="success">Jaunums</Badge></h3></Accordion.Header>
                        <Accordion.Body>


                            <img className='vingrosanasBildeTelefons' src='https://firebasestorage.googleapis.com/v0/b/salonssiluets-183bb.appspot.com/o/vingrosana.jpeg?alt=media&token=4f9f7bb8-2877-485e-a87a-131da8a0643c' alt="vingrošana" />



                            <ul>
                                <li>Dziļās muskulatūras attīstīšana</li>
                                <li>Vispārēja ķermeņa korpusa stiprināšana</li>
                                <li>Ķermeņa mobilitātes attīstīšana</li>
                            </ul>


                            <h5>Cena:</h5>
                            <ul>
                                <li>Individuāla vingrošana: <b> 20 &euro; </b></li>
                                <li>Vingrošana grupā (2 cilvēki): <b>10 + 10 &euro; </b></li>
                            </ul>

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3>Klasiskā masāža</h3></Accordion.Header>
                        <Accordion.Body>
                            <p> Šī masāža tiek rekomendēta veseliem cilvēkiem, lai uzturētu labu pašsajūtu, kā arī dažādu slimību profilaksei.

                                Masāža noder pie ļoti daudzām slimībām:


                                <ul>
                                    <li>Muskuļu spazmām</li>
                                    <li>Dažādiem muskuļu, saišu un nervu bojājumiem un traumām</li>
                                    <li>Mugurkaula osteohondrozes</li>
                                    <li>Neiralģijas</li>
                                    <li>Skoliozes</li>
                                    <li>Migrēnas</li>
                                    <li>Hipertoniskās slimības</li>
                                </ul>


                                Masāžas mērķis – paātrināt bojātu audu dzīšanas procesus, novērst mugurkaula problēmas un nostiprināt tā muskulatūru. Paātrinot asins cirkulāciju un noņemot tūskas, atdzīvināt bronhu, zarnu trakta, žultsceļu darbību un pazemināt vai
                                paaugstināt nervu sistēmas tonusu.


                                <h5>Cena:</h5>
                                <ul>
                                    <li>Muguras masāža (apkakle, mugura, krusti): <b> 30 &euro; </b></li>
                                    <li>Apkakles zonas + galvas masāža: <b> 25 &euro; </b></li>
                                    <li>Apkakles zonas masāža: <b> 20 &euro; </b></li>
                                    <li>Kājas un sēžas daļa: <b> 30 &euro; </b></li>
                                    <li>Visa ķermeņa masāža: <b> 45 - 50 &euro; </b></li>
                                    <li>Campi masāža (apkakle, pleci, galva): <b> 35 &euro; </b></li>
                                </ul>

                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3>Medus masāža</h3></Accordion.Header>
                        <Accordion.Body>
                            <h5>Cena:</h5>
                            <p>

                                <ul>
                                    <li>Vēders + mugura: <b> 30 &euro; </b></li>
                                    <li>Visam ķermenim: <b> 50 &euro; </b></li>
                                </ul>

                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header> <h3>Karsto akmeņu masāža</h3></Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Relaksējoša un atslabinoša masāža - baudījumus ķermenim un dvēselei!

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Mugura, apkakle: <b> 35 &euro; </b></li>
                                    <li>Visam ķermenim: <b> 55 &euro; </b></li>
                                </ul>

                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h3>Šokolādes masāža</h3></Accordion.Header>
                        <Accordion.Body>
                            <p>

                                Aromātiska un smaržīga SPA masāža

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Visam ķermenim: <b>55 &euro; </b></li>
                                </ul>


                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                        <Accordion.Header> <h3>Sejas kopšana</h3></Accordion.Header>
                        <Accordion.Body>
                            <p>

                                Sejas ādas tīrīšana, masāža + dekoltē zonas masāža

                                Sejas kopšanas procedūru laikā tiek veikta netraumatiska sejas tīrīšana, lai atdalītu atmirušās šūnas, likvidētu komedonus, pinnes un novērstu daudz citu problēmu rašanos, uzturot ārēji svaigu izskatu. Regulāru procedūru rezultātā
                                uzlabojas sejas krāsa, āda kļūst tvirta un atdzīvojas. Ja vēlamais efekts netiek sasniegts ar maskām un masāžām, to spēj panākt ar aparātiem, kas nostiprina saistaudus un baro šūnas, redzami izlīdzina sejas ādas grumbiņas un aizkavē
                                jaunu
                                veidošanos. Procedūrās tiek izmantotas plastificējošas aļģu maskas, šokolādes, porcelāna un mitrinošasa maskas.Procedūra domāta, lai stimulētu asinsriti, vielmaiņu un uzlabotu asinsriti, vielmaiņu un uzlabotu ādas elastību. Efekts ir
                                redzams tūlīt pēc procedūras, - sejas āda iegūst dzīvīguma un starojuma pievilcību.

                                <h5>Cena:</h5>

                                <ul>
                                    <li>Sejas vakuummasāža + dekoltē masāža (pīlings, masāža, maskas): <b> 35 &euro; </b></li>
                                    <li>Čampi masāža (galvas + apkakles masāža): <b> 30 &euro; </b></li>
                                    <li>Ekspress masāža galvai 30 min: <b> 25 &euro; </b></li>
                                </ul>

                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                        <Accordion.Header><h3>Vaksācija</h3></Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Piedāvājam izvēlēties depilāciju ar vaskiem, kas veidoti no dabīgām vielām un ir īpaši paredzēti jūtīgai ādai.

                                Procedūras veicam ar šķidro vasku.

                                <h5>Cena:</h5>

                                <ul>
                                    <li>Roku vaksācija: <b> 10-15 &euro; </b></li>
                                    <li>Padušu vaksācija: <b> 5-8 &euro; </b></li>
                                    <li>Bikini zonas vaksācija: <b> 12-25 &euro; </b></li>
                                    <li>Kāju vaksācija līdz celim: <b> 10-15 &euro; </b></li>
                                    <li>Kājas visā garumā: <b> 20-25 &euro;</b></li>
                                </ul>

                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

            </div>
        </div>
    )
}