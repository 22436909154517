
import { useEffect, useState } from 'react'

import PirmaDalaTelefons from './PirmaDalaTelefons'
import PirmaDalaDators from './PirmaDalaDators'

import Vingrosana from '../../images/vingrosana.jpeg'



export default function PirmaDala() {


    // Declare a new state variable with the "useState" Hook
    const [width, setWidth] = useState(window.innerWidth);


    useEffect(() => {

        window.addEventListener("resize", () => setWidth(window.innerWidth));

    }, [window.innerWidth]);

    return (
        <div id='masazas'>


                <div>
                    <h2 style={{margin: '3rem 1rem 1rem 1rem'}}><b>Jūsu veselībai un skaistumam piedāvājam</b></h2>
                   
                </div>


                {width < 768 ? <PirmaDalaTelefons /> : <PirmaDalaDators />}
        

        </div >
    )
}