import style from './Welcome.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import jsPDFInvoiceTemplate, { OutputType, jsPDF } from "jspdf-invoice-template";
import { useEffect, useState } from 'react';

import { useAuthContext, user } from '../Hooks/useAuthContext';
import { useCollection } from '../Hooks/useCollection'
import { timestamp, projectStorage } from "../Firebase/config"
import { useFirestore } from '../Hooks/useFirestore'
import { useInvoice } from '../Invoice/useInvoice'


import VideoInterface from '../VideoInterface/VideoInterface'

import '../fonts/PTSans-Regular.ttf'
import text from '../Invoice/font.js'
import logoImg from '../Invoice/image.js'

export default function Welcome() {

    const { authIsReady, user } = useAuthContext()
    const { updateDocument, response } = useFirestore('people')

    const { documents, error } = useCollection(
        'people', ["uid", "==", user.uid]
    )

    const { invoice } = useInvoice()


    const [isLongVid, setIsLongVid] = useState(null)
    const [isShortVid, setIsShortVid] = useState(null)

    const [isLongVidArray, setIsLongVidArray] = useState(null)
    const [isShortVidArray, setIsShortVidArray] = useState(null)

    const [isChosenVid, setIsChosenVid] = useState(null)
    const [isValid, setIsValid] = useState(false)
    const [isTimeLeft, setIsTimeLeft] = useState('')

    const [choosenBundle, setChoosenBundle] = useState(0)
    const [buy, setBuy] = useState(false)

    const [invoiceGenerated, setInvoiceGenerated] = useState(false)


    function randomNumber(length) {
        return Math.floor(Math.random() * length)
    }


    function shortVid() {

        if (isShortVidArray.length > 0) {
            const rand = randomNumber(isShortVidArray.length)
            setIsChosenVid(isShortVidArray[0])

            deleteVideo(isShortVidArray[0])
        }
    }

    function longVid() {

        if (isLongVidArray.length > 0) {
            const rand = randomNumber(isLongVidArray.length)
            setIsChosenVid(isLongVidArray[0])

            deleteVideo(isLongVidArray[0])
        }
    }



    function deleteVideo(position) {

        const sequence = documents[0].sequence
        sequence[position] = true

        const d = new Date();
        d.setSeconds(86400)
        const endDate = timestamp.fromDate(d)

        updateDocument({ id: documents[0].id }, { sequence, endDate, valid: true, activeVideo: position })
        setIsValid(true)
        setIsChosenVid(position)

        let time = new Date(documents[0].endDate.seconds * 1000)
        let timeS = time.toString()

        let timeInt = (parseInt(Date.parse(timeS)) - parseInt(Date.parse(new Date))) / 1000

        const h = (Math.floor(timeInt / 3600)).toString()
        timeInt -= 3600 * parseInt(h)

        const m = (Math.floor(timeInt / 60)).toString()
        timeInt -= 60 * parseInt(m)




        timeS = h + " stundas, " + m + " minūtes"
        setIsTimeLeft(timeS)
    }



    function activateProgram() {
        updateDocument({ id: documents[0].id }, { active: true })
    }



    useEffect(() => {

        if (documents) {

            if (choosenBundle !== 0 && buy !== false && invoiceGenerated === false) {
                generateInvoice()
                setInvoiceGenerated(true)
            }

            const longVideo = []
            for (let i = 0; i < 6; i++) {
                if (documents[0].sequence[i] === false) {
                    longVideo.push(i)
                }
            }

            const shortVideo = []
            for (let i = 6; i < 16; i++) {
                if (documents[0].sequence[i] === false) {
                    shortVideo.push(i)
                }
            }


            setIsLongVid(longVideo.length)
            setIsShortVid(shortVideo.length)

            setIsLongVidArray(longVideo)
            setIsShortVidArray(shortVideo)


            const date = timestamp.fromDate(new Date())
            const oldDate = documents[0].endDate

            if (oldDate.seconds < date.seconds) {


                if (documents[0].activeVideo != 'No video') {

                    updateDocument({ id: documents[0].id }, { valid: false })
                    setIsValid(false)

                    if (!documents[0].sequence.includes(false)) {
                        updateDocument({ id: documents[0].id }, { valid: false, active: false, paid: false, activeVideo: 'No video', downloadURL: '' })
                        setInvoiceGenerated(false)
                    }

                }


            } else {
                setIsValid(true)
                setIsChosenVid(documents[0].activeVideo)

                let time = new Date(documents[0].endDate.seconds * 1000)
                let timeS = time.toString()


                let timeInt = (parseInt(Date.parse(timeS)) - parseInt(Date.parse(new Date))) / 1000

                let h = 0;


                h = (Math.floor(timeInt / 3600)).toString()


                timeInt -= 3600 * parseInt(h)

                const m = (Math.floor(timeInt / 60)).toString()
                timeInt -= 60 * parseInt(m)


                timeS = h + " stundas, " + m + " minūtes"
                setIsTimeLeft(timeS)

            }



        }

    }, [documents, buy])


    function generateDate(fire) {
        var today = new Date()
        var dd = today.getDate();
        var mm = today.getMonth() + 1;

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        if (fire) {
            return `${dd}_${mm}_${yyyy}`
        } else {
            return `${dd}/${mm}/${yyyy}`
        }

    }


    function generateFolderDate() {
        var today = new Date()
        var dd = today.getDate();
        var mm = today.getMonth() + 1;

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        return `${mm}_${yyyy}`
    }


    function generateInvoice() {

        const doc = new jsPDF({
            unit: "mm",
            size: "A4",
            orientation: "portrait",

            marginLeft: 40,
            lineHeight: 1.2
        });

        doc.addFileToVFS("PTSans-Regular.ttf", text);
        doc.addFont('PTSans-Regular.ttf', 'PTSans', 'normal');

        doc.setFont('PTSans'); //



        doc.addImage(logoImg, 'JPEG', 10, 10, 45, 52);

        doc.setFontSize(16);
        doc.text("Rekvizīti", 180, 20);
        doc.setFontSize(12);
        doc.text("Aiva Parandjuka", 171, 30);
        doc.text("070273-11338", 173, 40);
        doc.text("Swedbank AS: LV26 HABA 0551 0429 1952 7", 118, 50);


        doc.setDrawColor(0, 0, 0);
        doc.line(10, 70, 200, 70);

        doc.setFontSize(16);
        doc.text("Maksātājs:", 10, 80);
        doc.setFontSize(12);
        doc.text(`${documents[0].name} ${documents[0].surname}`, 10, 90);

        doc.setFontSize(16);
        doc.text("Rēķina Nr:", 140, 80);
        doc.text(`${Math.floor(new Date() / 1000) - 1648000000}`, 170, 80);

        doc.setFontSize(12);
        doc.text("Datums:", 140, 90);
        doc.text(generateDate(false), 170, 90);

        doc.setDrawColor(0, 0, 0);
        doc.line(10, 125, 200, 125);

        doc.setDrawColor(0, 0, 0);
        doc.line(10, 152, 200, 152);

        doc.setFontSize(14);
        doc.text("Nosaukums", 10, 150);
        doc.setFontSize(12);

        switch (choosenBundle) {

            case 3:
                doc.text("Abonamenta maksa par 3 tiešsaistes nodarbībām", 10, 160);
                break

            case 8:
                doc.text("Abonamenta maksa par 8 tiešsaistes nodarbībām", 10, 160);
                break

            case 12:
                doc.text("Abonamenta maksa par 12 tiešsaistes nodarbībām", 10, 160);
                break

            default:
                doc.text("Abonamenta maksa par 16 tiešsaistes nodarbībām", 10, 160);
                break
        }


        doc.setFontSize(14);
        doc.text("Daudzums", 110, 150);
        doc.setFontSize(12);
        doc.text("1", 120, 160);

        doc.setFontSize(14);
        doc.text("Cena", 155, 150);
        doc.setFontSize(12);

        switch (choosenBundle) {

            case 3:
                doc.text("5", 159, 160);
                doc.setFontSize(14);
                doc.text("Kopā", 188, 150);
                doc.setFontSize(12);
                doc.text("5", 193, 160);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 165, 200, 165);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 167, 200, 167);
                doc.setFontSize(14);
                doc.text("Kopā jāmaksā: 5 €", 159, 180);
                doc.setFontSize(14);
                doc.text("Summa vārdiem:", 10, 215);
                doc.setFontSize(12);
                doc.text("Pieci eiro 00 centi", 48, 215);
                break

            case 8:
                doc.text("13", 159, 160);
                doc.setFontSize(14);
                doc.text("Kopā", 188, 150);
                doc.setFontSize(12);
                doc.text("13", 193, 160);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 165, 200, 165);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 167, 200, 167);
                doc.setFontSize(14);
                doc.text("Kopā jāmaksā: 13 €", 159, 180);
                doc.setFontSize(14);
                doc.text("Summa vārdiem:", 10, 215);
                doc.setFontSize(12);
                doc.text("Trīspadsmit eiro 00 centi", 48, 215);
                break

            case 12:
                doc.text("19", 159, 160);
                doc.setFontSize(14);
                doc.text("Kopā", 188, 150);
                doc.setFontSize(12);
                doc.text("19", 193, 160);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 165, 200, 165);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 167, 200, 167);
                doc.setFontSize(14);
                doc.text("Kopā jāmaksā: 19 €", 159, 180);
                doc.setFontSize(14);
                doc.text("Summa vārdiem:", 10, 215);
                doc.setFontSize(12);
                doc.text("Deviņpadsmit eiro 00 centi", 48, 215);
                break

            default:
                doc.text("25", 159, 160);
                doc.setFontSize(14);
                doc.text("Kopā", 188, 150);
                doc.setFontSize(12);
                doc.text("25", 193, 160);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 165, 200, 165);
                doc.setDrawColor(0, 0, 0);
                doc.line(10, 167, 200, 167);
                doc.setFontSize(14);
                doc.text("Kopā jāmaksā: 25 €", 159, 180);
                doc.setFontSize(14);
                doc.text("Summa vārdiem:", 10, 215);
                doc.setFontSize(12);
                doc.text("Divdesmit pieci eiro 00 centi", 48, 215);
                break
        }


        doc.setFontSize(12);
        doc.text(`Maksājot, lūdzu, pārliecinieties, vai esat norādījuši Rēķina nummuru: ${Math.floor(new Date() / 1000) - 1648000000}`, 10, 240);

        doc.setFontSize(10);
        doc.text("Šis rēķins tika automātiski ģenerēts", 80, 290);

        doc.save(`Rēķins ${documents[0].name} ${documents[0].surname} ${generateDate(false)}.pdf`);



        const pdf = doc.output('blob')

        var uploadTask = projectStorage.ref().child(`Invoices/${documents[0].uid}/${documents[0].name} ${documents[0].surname}/Rēķins ${documents[0].name} ${documents[0].surname} ${generateDate(true)}`).put(pdf);

        uploadTask.on('state_changed',
            (snapshot) => {

                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case projectStorage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case projectStorage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);

                    updateDocument({ id: documents[0].id }, { downloadURL })

                });
            }
        );


        var uploadTask = projectStorage.ref().child(`Rekini/${generateFolderDate()}/Rēķins ${documents[0].name} ${documents[0].surname} ${generateDate(true)}`).put(pdf);

        uploadTask.on('state_changed',
            (snapshot) => {

                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case projectStorage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case projectStorage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);

                    updateDocument({ id: documents[0].id }, { downloadURL })

                });
            }
        );

    }


    function handleBundle(bundle) {
        setChoosenBundle(bundle)
    }


    function handleBuy() {
        setBuy(true)

        const sequence = []

        for (let i = 0; i < 16; i++) {
            sequence.push(true)
        }

        switch (choosenBundle) {
            case 3:
                sequence[0] = false
                sequence[8] = false
                sequence[9] = false
                break

            case 8:
                sequence[0] = false
                sequence[1] = false
                sequence[2] = false
                sequence[3] = false
                sequence[8] = false
                sequence[9] = false
                sequence[10] = false
                sequence[11] = false
                break

            case 12:
                sequence[0] = false
                sequence[1] = false
                sequence[2] = false
                sequence[3] = false
                sequence[8] = false
                sequence[9] = false
                sequence[10] = false
                sequence[11] = false
                sequence[12] = false
                sequence[13] = false
                sequence[14] = false
                sequence[15] = false
                break

            default:
                for (let i = 0; i < 16; i++) {
                    sequence[i] = false
                }
                break
        }


        updateDocument({ id: documents[0].id }, { sequence })
    }

    function goBack() {
        setBuy(false)
        setChoosenBundle(0)
        setInvoiceGenerated(false)
    }


    return (
        <div className={style.welcome}>


            {error && <p>{error}</p>}

            {documents ? <>



                {!documents[0].active ? <>

                    {!buy &&
                        <Container>
                            <Row>
                                <Col onClick={() => { handleBundle(3) }} sm={6} lg={3} >
                                    <div className={style.bundleBox} >
                                        <h5>3 Nodarbības</h5>

                                        <h6>Nodarbību garums</h6>
                                        <ul>
                                            <li>2x 40min</li>
                                            <li>1x 60min</li>
                                        </ul>

                                    </div>

                                    <div className={style.bundleBoxPrice} style={choosenBundle === 3 ? { backgroundColor: "yellow" } : {}} >
                                        <h6>Cena: 5 €</h6>
                                    </div>
                                </Col>

                                <Col onClick={() => { handleBundle(8) }} sm={6} lg={3}>
                                    <div className={style.bundleBox}>
                                        <h5>8 Nodarbības</h5>

                                        <h6>Nodarbību garums</h6>
                                        <ul>
                                            <li>4x 40min</li>
                                            <li>4x 60min</li>
                                        </ul>
                                    </div>

                                    <div className={style.bundleBoxPrice} style={choosenBundle === 8 ? { backgroundColor: "yellow" } : {}}>
                                        <h6>Cena: 13 €</h6>
                                    </div>
                                </Col>

                                <Col onClick={() => { handleBundle(12) }} sm={6} lg={3}>
                                    <div className={style.bundleBox}>
                                        <h5>12 Nodarbības</h5>

                                        <h6>Nodarbību garums</h6>
                                        <ul>
                                            <li>8x 40min</li>
                                            <li>4x 60min</li>
                                        </ul>
                                    </div>

                                    <div className={style.bundleBoxPrice} style={choosenBundle === 12 ? { backgroundColor: "yellow" } : {}}>
                                        <h6>Cena: 19 €</h6>
                                    </div>
                                </Col>

                                <Col onClick={() => { handleBundle(16) }} sm={6} lg={3}>
                                    <div className={style.bundleBox}>
                                        <h5>16 Nodarbības</h5>

                                        <h6>Nodarbību garums</h6>
                                        <ul>
                                            <li>10x 40min</li>
                                            <li>6x 60min</li>
                                        </ul>
                                    </div>

                                    <div className={style.bundleBoxPrice} style={choosenBundle === 16 ? { backgroundColor: "yellow" } : {}}>
                                        <h6>Cena: 25 €</h6>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    }

                    {choosenBundle !== 0 && !buy && <button className={style.button} onClick={() => { handleBuy() }}>Pirkt {choosenBundle} nodarbību paku</button>}

                    {buy === true &&
                        <>
                            {documents[0].paid === false && <button className={style.button} onClick={() => { goBack() }}>Atpakaļ pie vingrošanas paku izvēles</button>}

                            <p>Rēķins ir apskatāms nospiežot zemāk esošo pogu</p>
                            <button className={style.button}><a target="_blank" href={documents[0].downloadURL}>Apskatīt rēķinu</a></button>
                            <p style={{ padding: '1.5rem' }}>Lai aktivizētu šo vingrošanas programmu, veiciet rēķina apmaksu. Kad apmaksa tiks apstiprināta, tad
                                zemāk esošā aktivizācijas pogas būs aktīva
                            </p>


                            <p>Lustīgu vingrošanu!</p>

                        </>
                    }



                    {documents[0].paid ? <button className={style.button} onClick={activateProgram}>Aktivizēt programmu</button> : <button className={style.buttonDisabled}>Aktivizēt programmu</button>}

                </>
                    :
                    <VideoInterface
                        isLongVid={isLongVid}
                        isShortVid={isShortVid}
                        shortVid={shortVid}
                        longVid={longVid}
                        isChosenVid={isChosenVid}
                        isValid={isValid}
                        isTimeLeft={isTimeLeft}
                    />
                }


            </>
                : <>Ielāde...</>}


        </div>
    )
}