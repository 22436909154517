import styles from './Login.module.css'
import './Log-and-Reg.css'
import { useState } from 'react'

import LogInForm from './LogInForm'
import ForgotPassword from './ForgotPassword'
import Signup from '../Sections/VideoPaka/SignUp/SignUp'

import { Container, Row, Col, Button } from 'react-bootstrap'

export default function LogIn() {

  const [login, setLogin] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)

  function switchLogin() {
    setLogin(!login)
  }


  function resetPassword() {
    setPasswordReset(!passwordReset)
  }

  return (
    <div style={{ 'marginTop': '3rem' }} className='log-reg-page'>

      {!passwordReset ? <>

        <Container>
          <Row>

            <Col className='kreisais' sm={12} lg={6}>
                           <LogInForm />
              {!login && <button className={styles.button} onClick={resetPassword}><i>Aizmirsi paroli?</i></button>}
            </Col>

            <Col className='labais' sm={12} lg={6}>
 <Signup />
            </Col>

          </Row>
        </Container>

      </>
        :
        <ForgotPassword setPasswordReset={setPasswordReset} />
      }

    </div>
  )
}