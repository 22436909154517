import logo from './logo.svg';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import React, {useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Link, NavLink, Navigate, useLocation} from 'react-router-dom'
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';


import Banner from './Sections/Banner/Banner';
import DavanuKarte from './Sections/DāvanuKarte/DavanuKarte'
import PirmaDala from './Sections/PirmāDaļa/PirmaDala';
import OtraDala from './Sections/OtrāDaļa/OtraDala'
import Kontakti from './Sections/Kontakti/Kontakti';
import VideoPaka from './Sections/VideoPaka/VideoPaka';
import Welcome from './Welcome/Welcome';

import { useAuthContext } from './Hooks/useAuthContext';
import Navbar from './Components/Navbar';
import Login from './LogIn/LogIn';


function App() {
  const { authIsReady, user } = useAuthContext()


  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-213023407-1");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  return (
    <div className="App">

      

      {authIsReady && (


     

          <Routes>

            <Route path='/' element={user ? <Navigate to='/video-paka' /> :
              <>
                <Navbar />
                <Banner />
                <DavanuKarte />
                
                <VideoPaka />
      
                <PirmaDala />
                <OtraDala />
                <Kontakti />
              </>
            } />



            <Route
              path="/video-paka"
              element={user ? <>  <Navbar /> <Welcome /> </> : <Navigate to='/' />}
            />


            <Route
              path="/login"
              element={user ? <Navigate to='/video-paka' /> : <Login />}
            />


          </Routes>

       


      )}


    </div>
  );
}

export default App;
