import styles from './Login.module.css'
import { useState } from 'react'


import { projectAuth } from '../Firebase/config'


export default function ForgotPassword({setPasswordReset}) {

    const [email, setEmail] = useState('')

    const handleSubmit = (e) => {
      e.preventDefault()

      projectAuth.sendPasswordResetEmail(email)
      .then(() => {
        setPasswordReset(false)
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ..
      });

    }
  
  
    return (
      <form onSubmit={handleSubmit} className={styles['login-form']}>
        <label>
          <span>Jūsu E-pasta adrese</span>
          <input 
            type="email" 
            onChange={(e) => setEmail(e.target.value)} 
            value={email}
          />
        </label>

        <button className={styles.button}>Sūtīt!</button>
        </form>
    )
}