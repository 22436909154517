import { Nav, Container, Navbar } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { useAuthContext } from "../Hooks/useAuthContext"
import { useLogout } from '../Hooks/useLogout'

// styles
import styles from './Navbar.module.css'

export default function NavBar() {


  const { logout } = useLogout()
  const { user } = useAuthContext()

  return (




    <Navbar bg="light" collapseOnSelect expand="lg" >

      <Container fluid>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
      
        <Nav className={styles.navBar} >



          {!user && (
            <>
              <a href="#sakums">Sākums</a>
              <a href="#davanu-karte">Dāvanu karte</a>
              <a href="#online-vingrosana">Online vingrošana</a>
              <a href="#masazas">Masāžas</a>
              <a href="#proceduras">Procedūras</a>
              <a href="#kontakti">Kontakti</a>
              
              <NavLink to="/login" ><b>Ielogoties!</b></NavLink>
          
            </>
          )}

          {user && (
            <div className={styles.logedIn}>
              <p style={{ display: 'inline' }}> {user.displayName}</p>

              <button className={styles.button} onClick={logout}>Iziet!</button>

            </div>
          )}

        </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>



  )
}