import styles from './Login.module.css'
import { useState } from 'react'


import { useLogin } from '../Hooks/useLogin'


// styles




export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  
  const { login, error, isPending } = useLogin()
  

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(email, password)

    
    login(email, password)
    
  }

  return (
    <form onSubmit={handleSubmit} className={styles['login-form']}>
      <h2>Pierakstīties!</h2>
      <label>
        <span>E-pasts</span>
        <input 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
      </label>
      <label>
        <span>Parole</span>
        <input 
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
        />
      </label>
     

      
      { !isPending && <button className={styles.button}><b>Pierakstīties</b></button> }
      { isPending && <p disabled>ielādē...</p> }
      { error && <p>{error}</p> }
      
    </form>
  )
}