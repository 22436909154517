import Davana from '../../images/Davana.mp4'
import style from './DavanuKarte.module.css'

export default function Banner() {

    return (
        <div id='davanu-karte'>

            <div className={style["video-karte"]}>

                <div className={style["custom-shape-divider-top-1635590189"]}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className={style["shape-fill"]}></path>
                    </svg>
                </div>


                <video width="auto" height="auto" autoPlay muted loop playsInline>
                    <source src={Davana} type="video/mp4" />
                </video>


                <div className={style["custom-shape-divider-bottom-1635590918"]}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className={style["shape-fill"]}></path>
                    </svg>
                </div>

            </div>


            


        </div>
    )
}