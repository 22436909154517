import '../PirmāDaļa/Dators.css'
import style from '../PirmāDaļa/PirmaDalaDators.module.css'
import { Tab, Row, Col, ListGroup, Badge } from 'react-bootstrap'


import Vingrosana from '../../images/vingrosana.jpeg'


export default function OtraDalaDators() {

    return (
        <div>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                <Row className='otraDala'>
                    <Col className={style.title} sm={4}>
                        <ListGroup>
                            <ListGroup.Item action href="#link1">
                                <h5>Mehāniskā limfodrenāža jeb t. s. "gaisa zābaki"</h5>
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link2">
                                <h5>Elektrolipolīze + ultraskaņas terapija (sonoforēze)</h5>
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link3">
                                <h5>Body Wrapping - ķermeņa ietīšanas procedūras</h5>
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link4">
                                <h5>Klasiskā (stikla kolbu) vakuummasāža</h5>
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link5">
                                <h5>Krūšu vakuummasāža, impulsveida vakuummasāža, pīlings, maskas</h5>
                            </ListGroup.Item>
                            <ListGroup.Item action href="#link6">
                                <h5>Impulsveida vakuummasāža sēžas daļai ’’Brazīliešu dibentiņš’’ + vakuummasāža</h5>
                            </ListGroup.Item>
                         {/*}   <ListGroup.Item action href="#link7">
                                <h5>Termo masāža jeb termobandāžas</h5>
                            </ListGroup.Item>
    */}
                        </ListGroup>
                    </Col>
                    <Col className={style.content} sm={8}>
                        <Tab.Content>
                            <Tab.Pane eventKey="#link1">

                                <h3>Mehāniskā limfodrenāža jeb t. s. "gaisa zābaki"</h3>
                                <p>
                                    Masāža ar mainīgu gaisa spiedienu, kā rezultātā uzlabojas asinsrite un limfas plūsma.
                                    Šī procedūra palīdz ārstēt celulītu, novērst dažādas izcelsmes tūskas, likvidēt venozās atteces traucējumus. Tā ir viena no pamatprocedūrām figūras korekcijai un cīņai ar celulītu, kas aktivizē vielmaiņu ādas taukšūnās.
                                    Limfodrenāža mazina nogurumu, veicina muskuļu atslābināšanos, palielina ādas elastību, uzlabo sejas krāsu, novērš dažādas izcelsmes tūskas, veicina grumbu izlīdzināšanu.
                                    Masāža ar mainīgu gaisa spiedienu ne vien patīkami relaksē, be
                                    t arī uzlabo asinsriti un limfas plūsmu, kā arī palīdz izvadīt no organisma kaitīgos vielmaiņas galaproduktus – toksīnus. Ļoti efektīva procedūra pie "smagu" un pietūkušu kāju problēmas.
                                </p>
                                <h5>Cena:</h5>

                                <ul>
                                    <li>Limfodrenāža visam ķermenim: <b> 18 &euro; </b></li>
                                    <li>Limfodrenāža (kājas+vēders): <b> 15 &euro; </b></li>
                                </ul>

                            </Tab.Pane>
                            <Tab.Pane eventKey="#link2">
                                <h3>Elektrolipolīze + ultraskaņas terapija (sonoforēze)</h3>
                                <p>

                                    Lokāli šķeļ liekos taukaudu sakopojumus samazinot ķermeņa problēmvietu apjomu un ārstē ieilgušu celulītu, vienlaicīgi tonizējot ādu.
                                    Ši ir viena no celulīta ārstēšanas pamatprocedūrām, kas ietver tauku depozītu likvidēšanu, ādas nostiprināšanu, ādas tonizēšanu.
                                    Ultraskaņa spēj visdziļāk ietikt tauku slānī un tā stimulē tauku metabolismu un enzīmus. Kopumā ultraskaņas mērķis ir cīņa ar vājiem ļenganiem saistaudiem uz dibena, vēdera un gurniem.
                                    Būtiska tās priekšrocība ir arī speciālo preparātu ievadīšana audos ar ultraskaņas palīdzību (sonofrēze). Tiek izkustināti tauku šūnu sakopojumi, veicinot aktīvu limfas atteci. Ultraskaņas terapija efektīvi novērš grumbuļainos
                                    veidojumus
                                    uz augšstilbiem.
                                </p>
                                <h5>Cena:</h5>
                                <ul>
                                    <li>Ultraskaņas terapija: <b> 25 &euro;</b></li>
                                    <li>Ultraskaņas terapija + vakuummasāža: <b> 30 &euro;</b></li>
                                </ul>


                            </Tab.Pane>
                            <Tab.Pane eventKey="#link3">
                                <h3>Body Wrapping - ķermeņa ietīšanas procedūras</h3>
                                <p>
                                    Speciālie kosmētiskie līdzekļi veicina vielmaiņas galaproduktu izvadīšanu no organisma. Savukārt speciālo kompresijas plēvju aptīšana nodrošina pastiprinātu limfodrenāžu.

                                    Pat pēc vienas šādas procedūras kājas kļūst manāmi tievākas, bet kopā ar šo metodi lietojamais kanēļa krēms darbojas arī kā aromaterapija, veicinot tauku vielmaiņu.

                                </p>

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Visam ķermenim: <b> 20 &euro;</b></li>
                                </ul>


                            </Tab.Pane>
                            <Tab.Pane eventKey="#link4">
                                <h3>Klasiskā (stikla kolbu) vakuummasāža</h3>
                                <p>
                                    Vakuummasāža ir veids kā cīnīties ar celulītu, samazināt apjomus un padarīt ādu tvirtāku.
                                    Vakuummasāža būtībā ir tāda pati masāža, kā visas citas masāžas, tikai tai ir cita tehnoloģija.
                                    Vakuummasāžas aparāts piesūcas pie ādas kā banka, tāpēc šāda masāža ir daudz dziļāka, līdz ar to tauku slāni var pacelt un kārtīgi izmasēt.
                                    Stikla kausiņā vacuums iesūc problēmzonas ādas laukumu, notiek intensīva un dziļa masāža, kas uzlabo vielmaiņu, asinsriti, celulīta šūnu membrānas kļūst caurlaidīgākas līdz ar to no šūnām labāk izvadās šlakviels, šūnas samazinās apjomā
                                    un
                                    āda kļūst gludāka.
                                    Masāžas rezultātā ādā pastiprināti veidojas elastīns un kalogēns, kas nodrošina ādas elastību.
                                </p>

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Vēders + muguras vakuummasāža: <b> 25 &euro; </b></li>
                                    <li>Vakuummasāža kājām (kājas+sēžas daļa): <b> 25 &euro; </b></li>
                                    <li>Vakuummasāža visam ķermenim: <b> 30 &euro; </b></li>
                                    <li>Vakuummasāža mugura + rokas: <b> 25 &euro; </b></li>
                                </ul>


                            </Tab.Pane>
                            <Tab.Pane eventKey="#link5">
                                <h3>Krūšu vakuummasāža, impulsveida vakuummasāža, pīlings, maskas</h3>
                                <p>
                                    Krūšu vakuummasāžas ietekmē uzlabojas krūšu ādas tonuss, samazinās redzamo striju reljefs, tiek veicināta saistaudu formēšanās.

                                    Procedūras laikā tiek pielietoti ādu attīroši, mitrinoši, barojoši kosmētiskie līdzekļi, kā arī ādu nostiprinošas maskas.

                                </p>

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Procedūra: <b> 35 &euro;</b></li>
                                </ul>


                            </Tab.Pane>
                            <Tab.Pane eventKey="#link6">
                                <h3>Impulsveida vakuummasāža sēžas daļai ’’Brazīliešu dibentiņš’’ + vakuummasāža</h3>

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Impulsveida vakuummasāža sēžas daļai: <b> 20 &euro;</b></li>
                                    <li>Impulsveida vakuummasāža sēžas daļai + vakuumma kolbu masāža: <b> 25 &euro;</b></li>
                                </ul>


                            </Tab.Pane>

                            {/*}
                            <Tab.Pane eventKey="#link7">
                                <h3>Termo masāža jeb termobandāžas</h3>
                                <p>
                                    Procedūru terapijas galvenais mērķis ir veikt lokalizētu sildīšanu tikai tajās vietās, kuras uzskatāmas par kritiskām, un kurās nepieciešamas veikt uzlabojumus no estētiskā viedokļa.
                                    Siltuma iedarbība veicina svīšanu, mikrocirkulācijas uzlabošanos un tauku sadedzināšanu.
                                </p>

                                <h5>Cena:</h5>
                                <ul>
                                    <li>Termoterapija + roku masāža: <b> 18-25 &euro;</b></li>
                                </ul>



                            </Tab.Pane>
    */}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
    )
}