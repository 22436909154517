import { useState, useEffect } from 'react'
import { projectAuth, projectStorage } from '../Firebase/config'

import { useAuthContext, user } from './useAuthContext'
import { useFirestore } from './useFirestore'



export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [isSettedUp, setIsSettedUp] = useState(false)
  const [isUserId, setIsUserId] = useState(null)
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [isPayload, setIsPayload] = useState(null)


  const { dispatch, user } = useAuthContext()
  const { addDocument, response } = useFirestore('people')


  const signup = async (name, surname, email, password, displayName) => {
    setError(null)
    setIsPending(true)


    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)
      console.log(res.user)

      setIsUserId(res.user.uid)
      setName(name)
      setSurname(surname)
      setEmail(email)


      if (!res) {
        throw new Error('Could not complete signup')
      }







      // add display name to user
      await res.user.updateProfile({ displayName })




      if (!isCancelled) {
        setIsPending(false)
        setError(null)
        setIsSettedUp(true)
      }




      setIsPayload(res.user)


    }
    catch (err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }


  }

  useEffect(() => {

    console.log(isSettedUp)
    if (isSettedUp) {

      const sequence = []
      for (let i = 0; i < 16; i++) {
          sequence.push(false)
      }

      addDocument({
        uid: isUserId,
        name,
        surname,
        email,
        paid: false,
        active: false,
        valid: false,
        admin: false,
        activeVideo: "No video",
        sequence,
        downloadURL: '',
        choosenBundle: 0
      })
    }

  }, [isSettedUp])


  useEffect(() => {
    if (response.success) {

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: isPayload })
    }
  }, [response.success])




  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending, isSettedUp }
}