import style from './VideoInterface.module.css'
import Navbar from '../Components/Navbar';


import { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';

import { useCollection } from '../Hooks/useCollection'

export default function VideoInterface({ isLongVid, isShortVid, shortVid, longVid, isChosenVid, isValid, isTimeLeft }) {


    const { documents: videos, error: errorVid } = useCollection('video')

    const [isVideoLink, setIsVideoLink] = useState(null)
    const [showDiv, setShowDiv] = useState(true)
    const [hideLeft, setHideLeft] = useState(true)

{/*
   document.addEventListener('contextmenu', event => event.preventDefault()) 
*/}
    function setDiv() {
        setShowDiv(!showDiv)

        setTimeout(function () {
            setShowDiv(true)
        }, 4000);

        setTimeout(function () {
            setHideLeft(false)
        }, 10000);
    }

    useEffect(() => {

        if (videos) {

            if (videos && isValid) {
                setIsVideoLink(videos[0].array[isChosenVid])
            }

        }

    }, [isChosenVid, videos, isValid])






    return (
        <div className={style.videoPaka} >



            <h1>Kur kustība, tur dzīvība!</h1>

{/*
            <button className={style.button} onClick={shortVid}>40 min nodarbība</button>
                    <button className={style.button} onClick={longVid}>60 min nodarbība</button>
    */} 

            {!isValid &&
                <>

                    <p>Jums ir pieejamas</p>


                    <p><b>{isShortVid}</b> : 40 min nodarbības</p>
                    <p><b>{isLongVid}</b> : 60 min nodarbības</p>


                    <h4 style={{'padding-top': '2rem'}}>Izvēlieties šodienas nodarbības ilgumu</h4>

                    <button className={style.button} onClick={shortVid}>40 min nodarbība</button>
                    <button className={style.button} onClick={longVid}>60 min nodarbība</button>

                    
                    <p style={{padding:'1.5rem'}}>Uzmanību! Izvēlētā nodarbība būs pieejama 24 h no pogas nospiešanas brīža</p>

                </>
            }

            {isValid &&
                <>
                    <p style={{padding:'1.5rem'}}>Nodarbība būs pieejama vēl: <b>{isTimeLeft}</b></p>

                    <div className={style.videoplayer}>
                        <div className={style.video} >

                            {showDiv && <div onMouseUp={setDiv} className={style.cover}></div>}


                           {hideLeft && <div className={style.bottom}></div>}
                           <div className={style.right}></div>
                           <div className={style.top}></div>
                            <iframe className={style.click} width="auto" height="auto" src={`${isVideoLink}`}
                                title="YouTube video player" allow="accelerometer autoplay clipboard-write
             encrypted-media gyroscope picture-in-picture"  autoPlay='1' ></iframe>


                        </div>






                    </div>


                </>
            }

            {/*
<video width="1500" height="1000" controls disablepictureinpicture controlslist="nodownload noplaybackrate">
  <source src="https://data-1.utreon.com/v/MG/Q2/Yz/xe5RYXk0Td6/xe5RYXk0Td6_1080p.mp4" type="video/mp4" />
</video>

        */}

        </div >
    )
}