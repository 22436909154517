import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDnlMge40urpPRVkkWLpUovGwcp2A9QXdM",
    authDomain: "salonssiluets-183bb.firebaseapp.com",
    projectId: "salonssiluets-183bb",
    storageBucket: "salonssiluets-183bb.appspot.com",
    messagingSenderId: "864657604877",
    appId: "1:864657604877:web:c4b9721e9eb0fe23f2f044"
  };
  
// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }